
.mobileTabs{
    display: none;
}
.rfqCard{
    width: 500px;
    padding: 15px;
}
.customFieldsCard{
    width: 550px;
}
.listFieldCards{
    width:360px;
}
.vendorCard{
    width: 300px;
}
.taskCard{
    min-width: 300px;
}
.purchaseRequestTag{
    padding: 4px 12px;
}
.requiredDate{
    display: flex;
}
.activityExtraMbl{
    display: none;
}
.userCards{
    height: 80%;
}
.roles{
    width: 90%;
    height: 100%;
}
.userCard{
    width: 360px;
}
.loginForm{
    width: 50%;
}
.forgotPass{
    justify-content: flex-end;
}

@media screen and (max-width:768px){
    .rfqCard{
        width: 400px;
    }
    .customFieldsCard{
        width: 100%;
        overflow-x: scroll;
    }
    .listFieldCards{
        width:90%
    }
    .layoutSlider{
        display: none;
    }
    .mobileTabs{
        display: flex;
    }
    .purchaseRequestTag{
        padding: 0px 2px;
    }
    .userCards{
        justify-content: center;
    }
}

@media screen and (max-width:600px){
    
    .rfqCard{
        width: 100%;
    }
    .statusCard{
        width: 95%;
    }
    .vendorCard{
        width: 100%;
    }
    .taskCard{
        min-width: 0px;
        width: 100%;
    }
    .vendorBadge{
        margin-right: 10px;
    }
    .requiredDate{
        flex-direction: column;
        align-items: flex-start;
    }
    .requiredDateText{
        font-size: 12px;
    }
    .activityExtra{
        display: none;
    }
    .activityExtraMbl{
        display: flex;
        justify-content: flex-end;
    }
    .userCards{
        align-items: center;
    }
    .roles{
        width: 100%;
    }
    .userCard{
        width: 100%;
    }
    .loginImg{
        display: none;
    }
    .loginForm{
        width: 100%;
    }
    .forgotPass{
        justify-content: center;
    }
}