.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%
}
.flex-column {
  display: flex;
  flex-direction: column;
  flex:1;
}
.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #e7e8eb;
}
.cover{
  width:100%;
  height:100%;
}
.trigger {
  padding: 0 24px;
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  font-weight: bold;
}

.trigger:hover {
  color: #000;
}

.logo {
  margin: 16px;
  padding:5px;
  background: #2beedea9;
  color:#fff;
  overflow: hidden;
  
}

.margin-vertical{
  margin:15px 0px;
}

.margin-horizontal{
  margin:0px 15px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.border-grey{
  border:2px solid #f2f5f5;
  padding: 10px 15px;
}

.shadow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.hovercard:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tooltip{
  padding: 10px;
}

.tooltip > div{
  margin: 5px 0px;
}

.title{
  font-weight: bold;
}

#pricing td, #pricing th {
  border: 1px solid #ddd;
  padding: 4px 6px;
  font-size: 0.8rem;
}

.custom-tabel tr:nth-child(even){background-color: #f2f2f2;}

.custom-tabel tr:hover {background-color: #ddd;}

.custom-tabel th {
  text-align: left;
  font-weight: 500;
  background-color: #f2f2f2;
}
.ant-tabs-content{
  height: 100%;
}
.ant-tabs-tabpane{
  height: 100%;
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 250px;
  margin-right: 8px;
}
.add-item-modal{
  width:50vw
}

.notif{
  width:35vw;
}

.table-row-colord{
  background-color: #ff7f7f40;
}
.table-row-deleted{
  opacity: 0.5;
}

.valueCards{
  margin-right:10px;
}
.valueFont{
font-weight: bold;
font-size: 1.5rem;
}

.status-card{
 width:200px;
}

.ant-table-wrapper,.ant-spin-nested-loading,.ant-spin-container,.ant-table,.ant-table-container,.ant-table-body{
  height:100%;
}

.site-layout-background{
  padding: 24px 16px;
}

.ant-table-footer{
background-color: transparent;
}

@media only screen and (max-width: 600px) {
  .add-item-modal{
    width:90vw
  }
  .notif{
    width:100vw;
  }
  .valueCards{
    margin-bottom:10px;
  }
  .status-card{
    width:auto;
    min-width: 100px;
   }
}